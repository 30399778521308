<template>
  <div class="preview dark">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/" >
            <el-breadcrumb-item
                v-for="(n, i) in project.routes"
                :key="i">
                <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
                {{n.name}}</span>
                <span v-else style="color: #b3bfc7">
                {{n.name}}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
        
        <div class="chart-area" v-if="!noData">
          <div id="relationRef" class="chart"></div>
        </div>
        <empty info="血缘" v-if="noData" />
    </div>
    
  </div>
  
</template>
<script>
import * as echarts from "echarts";
import { ref, onMounted, getCurrentInstance, nextTick } from "vue";
import { getSpaceOrPrjDetails, jumpRouteSetup } from "common/authBtn";
import { useMutataions } from "components/util/mapMutations";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import Empty from 'components/common/forms/Empty';
import _ from "underscore";

export default {
  name: "Relation",
  components: {
    Empty
  },
  setup(props, {emit}) {
    const $router = useRouter();
    const store = useStore();
    const mutation = useMutataions("Preview", ["setProject"]);
    let loading = ref(false), project = ref({}), relationObj = ref({children: []}), noData = ref(true);
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;

    const init = () => {
        noData.value = true;
        getSpaceOrPrjDetails(store).then(res => {
            loading.value = false;
            project.value = res;
            mutation.setProject(res);
            $api.sp.getRelationInfos({
                prjId: project.value.id
            }).then(data => {
                let result = parseData(data);
                if(!noData.value) {
                    renderChart(result);
                }   
            })
        })
    };

    const parseData = (data) => {
        let page = {}, list = [], result = {name: data.name, children: []};
        if(data.contents) {
            noData.value = false;
            data.contents.forEach(e => {
                if(!page[e.pageName]) {
                    page[e.pageName] = [];
                }
                e.coms.forEach(c => {
                    page[e.pageName].push(JSON.parse(c));
                })
            })
        }
        
        if(data.map) {
            for(let key in data.map) {
                let obj = {
                    name: key,
                    children: []
                }
                for(let p in page) {
                    if(page[p].length > 0) {
                        let obj2 = {
                            name: p,
                            children: []
                        }
                        data.map[key].forEach(v => {
                            let scrObj = _.find(page[p], item => {
                                return item.id == v;
                            })
                            if(scrObj) {
                                let obj3 = {
                                    id: scrObj.id,
                                    name: scrObj.name,
                                    children: []
                                }
                                if(scrObj.type == 'layout') {
                                    let list2 = repeatDataFn(scrObj.layout, scrObj.type);
                                    obj3.children = list2;
                                }else if(scrObj.children && scrObj.children.length > 0) {
                                    let list3 = repeatDataFn(scrObj.children, scrObj.type);
                                    obj3.children = list3;
                                }

                                obj2.children.push(obj3);
                            }else {
                                page[p].forEach(e => {
                                    if(e.type != 'layout' && e.children && e.children.length > 0) {
                                        let list4 = repeatDataFn(e.children, e.type, v);
                                        obj2.children = [...obj2.children, ...list4];
                                    }
                                })
                            }
                        })
                        if(obj2.children.length > 0) {
                            obj.children.push(obj2);
                        }
                    }
                }
                // for(let key2 in data.map[key]) {
                //     let obj2 = {
                //         id: key2,
                //         name: key2,
                //         children: []
                //     }
                //     data.map[key][key2].forEach(v => {
                //         let scrObj = _.find(list, item => {
                //             return item.id == v;
                //         })
                //         if(scrObj) {
                //             let obj3 = {
                //                 id: scrObj.id,
                //                 name: scrObj.name,
                //                 children: []
                //             }
                //             if(scrObj.type == 'layout') {
                //                 let list2 = repeatDataFn(scrObj.layout, scrObj.type);
                //                 obj3.children = list2;
                //             }else if(scrObj.children && scrObj.children.length > 0) {
                //                 let list3 = repeatDataFn(scrObj.children, scrObj.type);
                //                 obj3.children = list3;
                //             }

                //             obj2.children.push(obj3);
                //         }else {
                //             list.forEach(e => {
                //                 if(e.type != 'layout' && e.children && e.children.length > 0) {
                //                     let list4 = repeatDataFn(e.children, e.type, v);
                //                     obj2.children = [...obj2.children, ...list4];
                //                 }
                //             })
                //         }
                //     })
                //     obj.children.push(obj2);
                // }
                result.children.push(obj);
            }
        }
        
        return result;
    }; 

    const repeatDataFn = (data, type, comId) => {
        let list = [];
        if(type == 'layout') {
            data.forEach(x => {
                x.forEach(y => {
                    if(typeof y.comp.transformData == 'string' && y.comp.transformData.indexOf('parentParams') != -1) {
                        let obj = {
                            id: y.comp.id,
                            name: y.comp.name,
                            children: []
                        }
                        list.push(obj);
                    }else if(typeof y.comp.transformData != 'string') {
                        let str = JSON.stringify(y.comp.transformData);
                        if(str.indexOf('parentParams') != -1) {
                            let obj = {
                                id: y.comp.id,
                                name: y.comp.name,
                                children: []
                            }  
                            list.push(obj);
                        }
                    }
                })
            })
        }else {
            data.forEach(v => {
                let obj = null;
                if(comId && v.id == comId) {
                    obj = {
                        id: v.id,
                        name: v.name,
                        children: []
                    }
                    if(v.type == 'layout') {
                        let list2 = repeatDataFn(v.layout, v.type);
                        obj.children = list2;
                    }else if(v.children && v.children.length > 0) {
                        let list3 = repeatDataFn(v.children, v.type);
                        obj.children = list3;
                    }
                    list.push(obj);
                }else if(v.type != 'layout' && typeof v.transformData == 'string' && v.transformData.indexOf('parentParams') != -1) {
                    obj = {
                        id: v.id,
                        name: v.name,
                        children: []
                    }
                    if(v.children) {
                        let list2 = repeatDataFn(v.children, v.type);
                        obj.children = list2;
                    }
                    list.push(obj);
                }else if(v.type != 'layout' && typeof v.transformData != 'string') {
                    let str = JSON.stringify(v.transformData);
                    if(str.indexOf('parentParams') != -1) {
                        obj = {
                            id: v.id,
                            name: v.name,
                            children: []
                        }
                        if(v.children) {
                            let list2 = repeatDataFn(v.children, v.type);
                            obj.children = list2;
                        }
                        list.push(obj);
                    }

                }
            })
        }

        return list;
    };

    const renderChart = (data) => {
      relationObj.value = data;
      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove"
        },
        series: [
          {
            type: "tree",
            id: 0,
            name: "tree1",
            data: [data],
            roam: true,
            top: "0",
            left: "8%",
            bottom: "0%",
            right: "20%",
            symbolSize: 7,
            symbol: 'emptyCircle',
            edgeForkPosition: "63%",
            initialTreeDepth: 4,
            lineStyle: {
              width: 2,
              color: "rgba(255,255,255,.4)"
            },
            label: {
              backgroundColor: "rgba(222, 200, 34, 1)",
              padding: 2,
              borderRadius: 4,
              position: "right",
              verticalAlign: "middle",
              align: "left",
              fontSize: 12
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left"
              }
            },
            emphasis: {
              focus: "descendant"
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      };
      nextTick(() => {
        let chartDom = document.getElementById("relationRef");
        let myChart = echarts.init(chartDom);
        option && myChart.setOption(option);
        myChart.resize();
      })
    };

    const jumpSpace = (n) => {
      if (project.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
            $router.push('/Main/SpaceDetail');
        })
      }
    };

    const getStyle = () => {
        let h = repeatFn(relationObj.value.children);
        return {
            height: h + "px",
            minHeight: 750+"px"
        };
    };

    const repeatFn = (list) => {
        let result = 0, defautlH = 40;
        list.forEach(item1 => {
            result += item1.children ? item1.children.length * defautlH : defautlH;
            if(item1.children.length > 0) {
                let count = repeatFn(item1.children);
                result += parseInt(count);
            }
        })
        return result;
    };

    onMounted(() => {
        init();
    });

    return {
        loading,
        project,
        noData,

        jumpSpace,
        getStyle
    };
  }
};
</script>
<style lang="scss" scoped>
.chart-area {
  width: 100%;
  height: 100%;
  padding: 24px;

  .chart {
    width: inherit;
    height: inherit;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
  }
}
</style>
